<template>
  <section class="container mx-auto py-6 md:py-10">
    <h2
      class="text-center sm:text-left section-heading sm:w-full mx-auto sm:mx-0"
    >
      Driving Tangible Results for Leading Enterprises
    </h2>
    <div
      class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-14 pt-8 pb-10"
    >
      <template v-if="isLoading">
        <div v-for="i in 6" :key="i">
          <UtilsSkeletonLoading class="rounded-lg h-40 w-full mb-4 card-img" />
          <UtilsSkeletonLoading class="w-full h-14 mb-3" />
          <UtilsSkeletonLoading class="w-full h-12" />
        </div>
      </template>

      <template v-else>
        <ArticleCard
          v-for="content in contentsData"
          :key="content.id"
          :with-logo="true"
          :logo="
            content.attributes?.study_case_client?.data?.attributes?.image?.data
              ?.attributes?.url
          "
          :title="content.attributes?.title"
          :desc="content.attributes?.layout_component?.[0]?.description"
          :image-url="content.attributes?.image?.data?.attributes?.url"
          :slug="`/work/${content.attributes?.slug}`"
        />
      </template>
    </div>
    <div class="mx-auto text-center">
      <NuxtLink
        to="/work"
        title="Case Studies"
        class="btn btn-md md:btn-lg btn-primary-reverse w-max mx-auto"
      >
        Explore Our Success Stories
      </NuxtLink>
    </div>
  </section>
</template>

<script setup>
const isLoading = ref(true)

const contentsData = ref([])

const getContents = async () => {
  const { data, error, pending } = await useSuitmediaApiData('/api/contents', {
    query: {
      'pagination[pageSize]': 9,
      populate:
        'image,layout_component,layout_component.type,study_case_client.image',
      'filters[$and][0][layout][$eq]': 'study-case',
      sort: 'publish_at:desc',
      'filters[$and][1][study_case_is_highlight][$eq]': true
    }
  })
  isLoading.value = pending.value

  if (error.value) {
    throw createError({
      statusCode: 404,
      message: `Case Studies does not exist`
    })
  }

  return data.value
}

const contents = await getContents()
contentsData.value = contents.data
</script>

<style lang="scss" scoped>
.card-img {
  position: relative;
  padding-bottom: calc(210 / 373) * 100%;
  display: block;
}
</style>
